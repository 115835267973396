import 'css/base/variables.css'; // variaveis
import 'css/vendor/tippy-js.css';
import 'tippy.js/animations/shift-away-subtle.css';
import "css/index.css"; // entrada


document
	.addEventListener("DOMContentLoaded", () =>{
		
		// if(!window.matchMedia('(max-width:768px)').matches){}

			document.body.classList.add("DOMContentLoaded")
		
	}
	)


